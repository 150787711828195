import { registerApplication } from 'single-spa'
import { RegistrationFunc } from './registration'
import * as wootricApp from '@local/wootric-single-spa'

export const wootric: RegistrationFunc = (globalCustomProps) => {
  registerApplication({
    name: 'wootric-single-spa',
    activeWhen: '/restaurants/admin',
    customProps: globalCustomProps,
    app: wootricApp
  })
}
