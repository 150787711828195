import {
  AuthConfig,
  AuthFromCookie,
  AuthOptions,
  BaseAuthClient,
  filterSessionState,
  getAuthFromCookie,
  getEnvironmentOptions,
  getSessionState,
  getUserSessionState,
  logout,
  replaceFetch,
  replaceXHR,
  setCustomerCookie
} from '@toasttab/authentication-utils'
import { AuthClient } from './AuthClient'

/**
 * Function called to setup the interception processus (by using `replace*` or
 * `wrap*` for `Fetch` and `XHR`).
 * ```js
 * // Default behavior
 * (auth, config) => {
 *   replaceFetch(auth, config)
 *   replaceXHR(auth, config)
 *   return undefined
 * }
 * ```
 * @param auth The AuthClient created based on config.
 * @param config The config used to create the Toast wrapper AuthClient.
 */
type InterceptionSetup<T> = (auth: BaseAuthClient, config: AuthConfig) => T

function defaultInterceptionSetup(auth: BaseAuthClient, config: AuthConfig) {
  replaceFetch(auth, config)
  replaceXHR(auth, config)
}

function getAuthClient(
  authOptions: AuthOptions,
  authFromCookie: AuthFromCookie
) {
  if (authFromCookie.hasAuthCookie) {
    const { auth, userInfo } = authFromCookie
    return getUserSessionState().then(
      (userSessionState) =>
        new AuthClient(
          auth,
          authOptions,
          {
            ...userInfo,
            ...userSessionState
          },
          'toast'
        )
    )
  }

  // Could be using `initClientWithEnvironment` if we can omit `authOptions`
  return AuthClient.initClient(authOptions)
}

/**
 * Creates an auth client based on the options provided, and replaces the
 * implementation of XHR and Fetch. See AuthConfig for more information about
 * the configuration.
 * ```js
 * // Example with default configuration
 * const { auth } = await initAuthClient()
 * ```
 * @param config The config used to create the Toast wrapper AuthClient.
 * @param interceptionSetup An optional function that you can pass to initialize
 * the interception processus. By default, will use `replaceFetch` and
 * `replaceXHR`.
 * @throws If there's an issue loading the session information, or getting the
 * Auth0 session for the current user (not logged in, or invalid session).
 * @returns The AuthClient, modified AuthConfig and the results of your
 * interception setup, in a Promise.
 */
export function initAuthClient<T>(
  config: AuthConfig = {},
  interceptionSetup?: InterceptionSetup<T>
) {
  const { environment } = config
  const cookieState = getAuthFromCookie()
  const authOptions = getEnvironmentOptions(environment)

  setCustomerCookie()

  return Promise.all([
    getAuthClient(authOptions, cookieState),
    getSessionState()
  ])
    .then(async ([auth, sessionState]) => {
      if (!sessionState) {
        return {
          auth,
          config,
          preparedInterceptions: undefined,
          restaurantInfo: null
        }
      }
      const { restaurantInfo, internalInfo } = filterSessionState(sessionState)
      const { resolvedRestaurantPermissions, ...authContextHeaders } =
        internalInfo
      auth._setRestaurantPermissions(resolvedRestaurantPermissions)
      const modifiedConfig = {
        originsWithAuth: [window.location.origin],
        authContextHeaders,
        ...config
      }
      const preparedInterceptions = (
        interceptionSetup ?? defaultInterceptionSetup
      )(auth, modifiedConfig)
      // Should we have a `lazy` setting from AuthConfig?
      await auth.checkUserSession()
      return {
        auth,
        config: modifiedConfig,
        preparedInterceptions,
        restaurantInfo
      }
    })
    .catch((e) => {
      /**
       * We want to log out users with possibly invalid session instead of
       * showing a partially loaded blank page.
       * Auth0Client may fail to initialize in some edge cases with a Login
       * required error, while they say that it's a recoverable error
       * @see https://github.com/auth0/auth0-spa-js/blob/56a8f62c61a1ea4b942e310db986356a76e220ec/src/Auth0Client.ts.
       * It can be reproduced by logging in Toastweb, then blocking your user
       * in Auth0, then reloading the page. Toastweb session won't get
       * invalidated while Auth0 one will, so you'll be able to partially load
       * the page, until this library fails to load Auth0 session.
       */
      console.warn(e)
      logout(authOptions.client_id)
      throw e
    })
}

/**
 * @deprecated Use `initAuthClient` instead
 */
export function initUmbrella() {
  return initAuthClient()
}
