// global
import { registerApplication } from 'single-spa'
// local
import { GlobalCustomProps } from '@local/global-custom-props'
import { importSpa } from '../utils/SystemHelpers/importSpa'
import { RegistrationFunc } from './registration'
import type { LDClient } from 'launchdarkly-js-client-sdk'

export const PRICE_SPA_FROM_WEX_ROOT = 'menu-price-spa-rendered-from-wex-root'

export const isPriceSpaServedFromWexRoot = (ldClient?: LDClient) =>
  ldClient?.variation(PRICE_SPA_FROM_WEX_ROOT, false)

export const isMenuPriceSpaPath = (pathname: string) =>
  pathname.startsWith('/restaurants/admin/menus/manager')

export const menuPriceSpa: RegistrationFunc = (
  customProps: GlobalCustomProps
) => {
  const { ldClient } = customProps

  if (isPriceSpaServedFromWexRoot(ldClient)) {
    // -------------------------------------------
    // "Menu manager"
    // -------------------------------------------
    registerApplication({
      name: 'menu-price-spa',
      activeWhen: ({ pathname }) => isMenuPriceSpaPath(pathname),
      customProps: {
        ...customProps,
        basename: '/restaurants/admin'
      },
      app: importSpa('menu-price-spa')
    })
  }
}
