/* eslint-disable no-undef */

/**
 * Get the size of the cookie in bytes
 * @returns the size of the cookie in bytes
 */
export function getCookieSize() {
  return new window.Blob([document.cookie]).size
}

export function getAllCookies(): [string, string][] {
  return (
    document.cookie
      .split(';')
      .map((s) => s.trim())
      .filter((s) => s !== '')
      // cookie names and values are separated by the first "=" in a cookie string
      // but it's still valid to also have more = characters within the value,
      // e.g. session_data=user=5&rx=8, should be parsed here as ['session_data", "user=5&rx=8"]
      .map((s) => s.split('='))
      .map(([name, ...parts]) => [name, parts.join('=')])
  )
}

/**
 * Clear cookies that are not essential to the app
 */
export function clearCookies(cookieRegex: RegExp) {
  getAllCookies()
    .map(([name]) => name)
    .filter(function (n) {
      return cookieRegex.test(n)
    })
    .forEach(function (n) {
      document.cookie =
        n +
        '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=.toasttab.com;path=/'
    })
}

export function getSizeByCookie() {
  const allCookies = getAllCookies()
  const cookieSizes = allCookies.map(
    ([cookieName, value]) =>
      [cookieName, getSize(`${cookieName}=${value};`)] as [string, number]
  )

  const nonAuth0Cookies = cookieSizes.filter(
    ([cookieName]) => !isAuth0Cookie(cookieName)
  )
  const auth0Cookies = cookieSizes.filter(([cookieName]) =>
    isAuth0Cookie(cookieName)
  )

  const auth0CookieSize = auth0Cookies.reduce((acc, [, size]) => acc + size, 0)
  const auth0Entry: [string, number][] =
    auth0Cookies.length > 0 ? [['auth0', auth0CookieSize]] : []

  return [...nonAuth0Cookies, ...auth0Entry].reduce((res, [name, size]) => {
    res[name] = res[name] ?? 0
    res[name] += size
    return res
  }, {} as Record<string, number>)
}

function getSize(s: string) {
  return new Blob([s]).size
}

function isAuth0Cookie(cookie: string) {
  return cookie.indexOf('auth0') !== -1
}
