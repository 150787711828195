import {
  differenceInDays,
  parseDate
} from '@toasttab/buffet-pui-date-utilities'

export const bootstrap = async () => {
  return Promise.resolve()
}

/**
 * Adds Medallia beacon to the DOM
 * @returns {Promise<void>}
 */
export const mount = async () => {
  // Create element with global variables
  const setupScript = document.createElement('script')
  const medalliaDataLayer = await fetchMedalliaDataLayer()
  setupScript.type = 'text/javascript'
  setupScript.async = true
  setupScript.innerHTML = `window.MEDALLIA_PROPS = ${JSON.stringify(
    medalliaDataLayer
  )};`
  document.body.appendChild(setupScript)

  // Create element to load medallia sdk
  const medalliaLoader = document.createElement('script')
  medalliaLoader.id = 'medallia-beacon'
  medalliaLoader.type = 'text/javascript'
  medalliaLoader.async = true
  medalliaLoader.src =
    'https://resources.digital-cloud-west.medallia.com/wdcwest/797329/onsite/embed.js'

  document.body.appendChild(medalliaLoader)

  return Promise.resolve()
}

/**
 * Removes the Medallia beacon from the DOM
 */
export const unmount = async () => {
  const beacon = document.getElementById('medallia-beacon')

  if (beacon && document.body.contains(beacon)) {
    document.body.removeChild(beacon)
  }

  return Promise.resolve()
}

/**
 * Fetches the data needed to initialize the Medallia survey
 * @returns the medallia data layer object
 */
const fetchMedalliaDataLayer = async () => {
  try {
    const response = await fetch('/restaurants/admin/thirdPartyData')

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    const data = await response.json()

    const posGoLiveDate = data?.posGoLiveDate
      ? parseDate(data.posGoLiveDate, 'yyyyMMdd', 'en-US') || new Date(0)
      : new Date(0)
    const daysSinceGoLive = differenceInDays(new Date(), posGoLiveDate)
    const posGoLiveIsoString = posGoLiveDate.toISOString()

    return {
      thirtyDaysPastGoLive: daysSinceGoLive >= 30,
      ninetydaysPastGoLive: daysSinceGoLive >= 90,
      posGoLiveDate: posGoLiveIsoString,
      userGuid: data?.guid,
      restaurantGuid: data?.restaurantGuid
    }
  } catch (error) {
    console.error('Failed to fetch data:', error)
  }

  return {}
}
