import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  TypePolicies,
  RequestHandler
} from '@apollo/client'
import type { Hub } from '@sentry/types'
import { getCurrentEnvironment } from '@toasttab/current-environment'
import { createPersistedQueriesLink } from '@toasttab/persisted-queries-link'
import { errorLink } from './graphqlErrorLink'

const typePolicies: TypePolicies = {
  UserPreferencesQuery: {
    keyFields: []
  },
  NavigationQuery: {
    keyFields: []
  }
}
export const wraServerUrl = '/api/service/wex-restaurants-admin/v1/graphql'
export const ragServerUrl = '/api/service/restaurant-admin-graphql/v1/graphql'

export const getApolloClient = (options?: {
  customServerUrl?: string
  persistedQueries?: boolean
  sentry?: Hub
}) => {
  const httpLink = new HttpLink({
    uri: options?.customServerUrl || wraServerUrl
  })
  const links: (ApolloLink | RequestHandler)[] = [
    errorLink(options?.sentry),
    httpLink
  ]
  if (options?.persistedQueries) {
    links.unshift(
      createPersistedQueriesLink({
        disableErrorLogging: getCurrentEnvironment() !== 'preprod'
      })
    )
  }

  return new ApolloClient({
    cache: new InMemoryCache({ typePolicies }),
    link: ApolloLink.from(links),
    name: process.env.PKG_NAME,
    version: process.env.PKG_VERSION
  })
}
