import bigInt from 'big-integer'
import createAuth0Client from '@auth0/auth0-spa-js'
import {
  Audiences,
  AuthOptions,
  AuthProvider,
  BaseAuthClient,
  CompleteCustomerUserInfo,
  Domain,
  getCustomerUserInfoFromToken,
  getEnvironmentOptions,
  hasPermissionAtBitPosition,
  logout,
  PasswordlessAuthClient,
  phoneVerifyComplete,
  phoneVerifyStart,
  ToastEnvironment,
  updateOptOutPreference,
  convertTokenResponse,
  BackendTokenResponse
} from '@toasttab/authentication-utils'

type Authorities = 'toast' | 'auth0'

const REDIRECT_URI = `${window.location.origin}/login`
const ACTIVE_SESSION_KEY = 'UA-hasActiveSession'

// TODO: Should we have `CookeAuthClient` actually?
// TODO: Should we have `PhoneNumberVerificationClient`, extending
// `PasswordlessAuthClient`? It's probably a good pattern to separate everything
// in its own client and compose them to create a releasable client, right?
// But is it useful right now? I'll check with Friday's or Monday's Alexis.
// TODO: Use regions to group methods for the same business logic
export class AuthClient
  extends PasswordlessAuthClient
  implements BaseAuthClient
{
  private auth0Client: AuthProvider
  private options: AuthOptions
  private user: CompleteCustomerUserInfo
  // Eventually, this will be in PasswordlessAuthClient to support i18n
  private countryCode: string | null = null
  // Determines whether the token comes from Auth0 or the `__ag2s` cookie
  // In the latter case, we don't want to update user info from token claims
  private authority: Authorities

  /**
   * Initializes class attributes. Should not be called oustide of the library.
   * @param auth0Client
   * The AuthClient created based on config.
   * @param options
   * The Auth0 options used to create the Auth0Client to communicate with Auth0.
   * Depends on the running environment.
   * @param user
   * @param authority
   * The Auth0 options used to create the Auth0Client to communicate with Auth0.
   * Depends on the running environment.
   */
  constructor(
    auth0Client: AuthProvider,
    options: AuthOptions,
    user: CompleteCustomerUserInfo,
    authority: Authorities = 'auth0'
  ) {
    super(Domain.CUSTOMER, Audiences.CUSTOMER, ACTIVE_SESSION_KEY)
    this.auth0Client = auth0Client
    this.options = options
    this.user = user
    this.authority = authority
  }

  /**
   * Creates a client to interact with. You should use
   * `initClientWithEnvironment` (providing an environment or not), unless you
   * have precise options to give to Auth0Client initialization (such as the
   * `prompt` property).
   * @param options The Auth0 options used to create the Auth0Client to
   * communicate with Auth0. Depends on the running environment.
   * @throws If user session has expired or hasn't been created (e.g. user has
   * been blocked after logging in Toastweb).
   * @returns The AuthClient instance.
   */
  public static async initClient(options: AuthOptions) {
    const auth = await createAuth0Client({
      ...options,
      redirect_uri: REDIRECT_URI
    })
    const token = await auth.getTokenSilently()
    return new AuthClient(auth, options, {
      ...getCustomerUserInfoFromToken(token),
      resolvedRestaurantPermissions: '0',
      binaryCustomerPermissions: '0',
      customerPermissions: '0'
    })
  }

  /**
   * Creates a client to interact with, based on the given environment.
   * @param environment The current environment where the code is running.
   * @throws If user session has expired or hasn't been created (e.g. user has
   * been blocked after logging in Toastweb).
   * @returns The AuthClient instance.
   */
  public static initClientWithEnvironment(environment?: ToastEnvironment) {
    const options = getEnvironmentOptions(environment)
    return this.initClient(options)
  }

  /**
   * Returns logged in user information.
   * @returns User information.
   */
  public get userInfo() {
    return this.user
  }

  public checkUserSession() {
    return this.checkSession()
  }

  /**
   * This is the completion counter-part of `startConfirmIdentity`
   * @see {@link startConfirmIdentity}
   */
  public async completeConfirmIdentity(verificationCode: string) {
    return this.passwordlessToken(verificationCode)
  }

  /**
   * This is the completion counter-part of `startVerifyPhoneNumber`
   * @see {@link startVerifyPhoneNumber}
   */
  public async completeVerifyPhoneNumber(verificationCode: string) {
    this.validate('verification code', verificationCode)
    this.validate('identity', this.identity)

    return phoneVerifyComplete({
      verificationCode,
      phoneNumber: this.identity,
      countryCode: this.countryCode ?? ''
    }).then(async ({ token, expiresAt }) => {
      Object.assign(this.user, getCustomerUserInfoFromToken(token))
      this.setState(token, expiresAt)
      // Opt them back in, in case they opted out.
      await updateOptOutPreference(this.userInfo.guid, false)
    })
  }

  /**
   * Ideally, should be renamed
   * Get user token without prompting the user to log again.
   * @throws If user session has expired or hasn't been created (e.g. user has
   * been blocked after logging in Toastweb).
   * This will be called every time we send a request to our backend.
   */
  public async getTokenSilently() {
    // Will only return a token if it is valid (not expired)
    const passwordlessToken = this.checkAndReturnToken()
    if (passwordlessToken) {
      return passwordlessToken
    } else {
      this.resetStateOnExpiredToken()
    }

    const token = (await this.auth0Client.getTokenSilently()) as string
    if (this.authority === 'auth0') {
      Object.assign(this.user, getCustomerUserInfoFromToken(token))
    }
    return token
  }

  /**
   * @deprecated Please use the `@toasttab/check-permissions` package instead.
   * Determines and returns whether the logged in user has Toast permission for
   * the given bit position.
   * @param bitPosition The given bit position. You should rely on this file to
   * get the bit position of the permission you need to check `ToastPermissions.kt`
   * @see https://github.com/toasttab/toast-java-common/blob/development/src/main/java/com/toasttab/models/ToastPermissions.java
   * @throws If bitPosition is out of bounds, or not an integer.
   * @returns Whether the user has the given permission or not.
   */
  public hasToastPermissionAtBitPosition(bitPosition: number) {
    return hasPermissionAtBitPosition(
      this.user.resolvedToastPermissions,
      bitPosition
    )
  }

  /**
   * @deprecated Please use the `@toasttab/check-permissions` package instead.
   * Determines and returns whether the logged in user has restaurant permission
   * for the given bit position.
   * @param bitPosition The given bit position. You should rely on this file to
   * get the bit position of the permission you need to check `Permissions.kt`
   * @see https://github.com/toasttab/toast-java-common/blob/development/src/main/java/com/toasttab/models/Permissions.java
   * @throws If bitPosition is out of bounds, or not an integer.
   * @returns Whether the user has the given permission or not.
   */
  public hasRestaurantPermissionAtBitPosition(bitPosition: number) {
    return hasPermissionAtBitPosition(
      this.user.resolvedRestaurantPermissions,
      bitPosition
    )
  }

  /**
   * Logout user from the app and redirect them to the origin URL.
   */
  public logout() {
    return this.revokeToken().then(() => {
      logout(this.options.client_id)
    })
  }

  public optOutPhoneVerification() {
    return updateOptOutPreference(this.user.guid, true)
  }

  public async revokeToken() {
    return (
      super
        .revokeToken()
        // `revokeToken` may fail if the user didn't have a passwordless token
        // in their cookies
        .catch(() => this.resetState())
    )
  }

  /**
   * `confirm` is used with a verified phone number, to confirm one's identity.
   * @see {@link completeConfirmIdentity}
   */
  public async startConfirmIdentity() {
    this.validateVerifiedPhoneNumber()
    return this.passwordlessStart(this.user.internationalPhoneNumber)
  }

  /**
   * `verify` is used to register a new phone number, to later be used to
   * confirm one's identity. @see {@link completeVerifyPhoneNumber}
   * `phoneNumber` can be provided here to override user's one when updating the
   * verified phone number. The `phoneNumber` must not hold the country code,
   * and be specified in `countryCode` instead. When not provided, the
   * `countryCode` defaults to "+1" in the back-end.
   */
  public async startVerifyPhoneNumber(
    phoneNumber: string,
    countryCode: string = ''
  ) {
    this.validate('phone number', phoneNumber)

    return phoneVerifyStart({ phoneNumber, countryCode }).then(() => {
      // Save the phone number for `completeConfirmIdentity`
      this.identity = phoneNumber
      this.countryCode = countryCode
      this.identityType = 'phoneNumber'
    })
  }

  private validateVerifiedPhoneNumber() {
    if (!this.user.phoneNumber) {
      throw new Error('User does not have a phone number.')
    } else {
      // We only want to check the country code if user has a phone number
      if (!this.user.countryCode) {
        throw new Error('User does not have a country code.')
      }
    }
    if (!this.user.isPhoneNumberVerified) {
      throw new Error('Phone number is not verified.')
    }
  }

  public async refreshToken() {
    const tokenPromise = this.auth0Client.getTokenSilently({
      ignoreCache: true,
      detailedResponse: true
    }) as Promise<BackendTokenResponse>

    const { token, expiresAt } = await convertTokenResponse(tokenPromise)
    Object.assign(this.user, getCustomerUserInfoFromToken(token))
    this.setState(token, expiresAt)
  }

  public async startStepUpAuthentication() {
    return this.auth0Client.loginWithRedirect({
      scope: 'openid profile email offline_access sua',
      redirect_uri: REDIRECT_URI,
      ...getEnvironmentOptions()
    })
  }
  /**
   * Set the restaurant permissions (retrieved from the session state).
   * @param resolvedRestaurantPermissions
   * Restaurant permissions.
   * @private This shouldn't be used outside of the package.
   */
  _setRestaurantPermissions(resolvedRestaurantPermissions: string) {
    const parsed = bigInt(resolvedRestaurantPermissions, 2)
    this.user = {
      ...this.user,
      resolvedRestaurantPermissions,
      binaryCustomerPermissions: resolvedRestaurantPermissions,
      customerPermissions: parsed.toString(10)
    }
  }
}
