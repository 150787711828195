import { start } from 'single-spa'
import {
  InitAuthResult,
  initAuthClient
} from '@toasttab/user-authentication-js'
import {
  getApolloClient,
  getGlobalCustomProps,
  getI18nProps,
  ragServerUrl
} from '@local/global-custom-props'

import { initializePageViewTracking } from '@local/page-view-tracking'
import {
  devTools,
  ecomm,
  onboardingAdmin,
  restaurantAdmin,
  restaurantAdminHomePage,
  settings,
  toastAdmin,
  aiChatBot,
  sites,
  headerNotifications,
  medallia,
  wootric,
  menuPriceSpa,
  isPriceSpaServedFromWexRoot
} from '../registration'
import { runCookieSafetyValve } from '@toasttab/cookie-safety-valve'
import { initializeHeapAnalytics } from '@local/analytics-utils'
import { createClient, createContext } from '@local/launch-darkly'
import { fetchInitialData } from './initialDataQuery'
import {
  captureException,
  getCapmanOrigin,
  isOnProductionEnvironments,
  setupSentry
} from '../utils'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { useFeatureFlags } from '@local/feature-flag-utils'

const isOnDev = !isOnProductionEnvironments()

export async function initialize() {
  const sentry = await setupSentry({
    publicKey: '464d20e84eef41f987e0f56315e19c6a@o37442',
    projectId: '5758707'
  })

  // REMOVE ONCE WE UPDATE TO REACT 17.0.2
  // Reference: https://github.com/facebook/create-react-app/issues/10474
  //@ts-ignore
  if (!window.crossOriginIsolated) window.SharedArrayBuffer = ArrayBuffer

  await initAuthClient({
    originsWithAuth: [window.location.origin, getCapmanOrigin()],
    doesRequestNeedAuth(url) {
      // If we are running in a dev or preprod environment, attach the auth header to all graphql requests.
      if (isOnDev) {
        return /graphql/.test(url.pathname) && /localhost/.test(url.hostname)
      }
      return false
    }
  })
    .then(async (props) => {
      const restaurantAdminApolloClient = getApolloClient({
        customServerUrl: ragServerUrl,
        persistedQueries: true,
        sentry
      })

      const ldClient = await initLaunchDarklyClient(
        restaurantAdminApolloClient,
        props
      )
      const globalCustomProps = getGlobalCustomProps(props, {
        i18n: getI18nProps(props),
        banquetStartTime: new Date().getTime().toString(),
        apolloClient: getApolloClient(),
        restaurantAdminApolloClient,
        ldClient: ldClient ? ldClient : undefined
      })
      ldClient && headerNotifications(globalCustomProps)

      const { isMedalliaEnabled } = useFeatureFlags(
        globalCustomProps.featureFlags,
        globalCustomProps.ldClient
      )

      // Register heap analytics here
      initializeHeapAnalytics(globalCustomProps)
      // Register ai chat bot widget here.
      aiChatBot(globalCustomProps)
      // Register dev tools widget(s) here.
      devTools(globalCustomProps)
      // Register restaurant admin layout set here.
      restaurantAdmin(globalCustomProps)
      // Register the restaurant home page layout set here.
      restaurantAdminHomePage(globalCustomProps)
      // Register toast admin layout set here.
      toastAdmin(globalCustomProps)
      // Register onboarding admin layout set here.
      onboardingAdmin(globalCustomProps)
      // Register settings layout set here.
      settings(globalCustomProps)
      // Registering ecomm
      ecomm(globalCustomProps)
      // Registering online-ordering-pro and websites config
      sites(globalCustomProps)

      if (isPriceSpaServedFromWexRoot(globalCustomProps.ldClient)) {
        // Register the menu price spa, which was spun out from restaurant-admin-layout
        menuPriceSpa(globalCustomProps)
      }

      start({ urlRerouteOnly: true })

      initializePageViewTracking(globalCustomProps)
      if (globalCustomProps.featureFlags?.['wex-2423-cookie-safety-value']) {
        safelyRunCookieSafetyValve(
          globalCustomProps.auth?.userInfo?.isToastAdministrator
        )
      }
      // Load either Medallia or Wootric to show the NPS survey
      if (isMedalliaEnabled) {
        medallia(globalCustomProps)
      } else {
        wootric(globalCustomProps)
      }
    })
    .catch((err) => {
      sentry?.withScope((scope) => {
        scope.setLevel('info')
        scope.setFingerprint(['{{ default }}', 'initAuthClient'])
        scope.setTransactionName('initAuthClient')
        scope.setContext('custom_info', {
          url: window.location.href
        })

        sentry.captureException(err)
      })
    })
}

async function initLaunchDarklyClient(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  props: InitAuthResult
) {
  try {
    /**
     * Fetch initial data for the user preferences and permissions
     */
    const initialData = await fetchInitialData(apolloClient)
    /**
     * Create a context object to pass to the launch darkly client
     */

    const { __typename, ...permissions } = initialData.data?.permissions ?? {}
    const ldContext = createContext({
      user: props.auth.userInfo,
      restaurantInfo: props.restaurantInfo,
      featureOptIns: initialData.data?.userPreferences?.userFeatureOptIns,
      permissions
    })
    /**
     * Create the launch darkly client
     */
    return await createClient(ldContext)
  } catch (err) {
    captureException(err)
  }
}

function safelyRunCookieSafetyValve(isInternalUser = false) {
  try {
    // Add cookie safety valve script
    runCookieSafetyValve(isInternalUser)
  } catch (err) {
    captureException(err)
  }
}
