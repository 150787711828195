import { registerApplication } from 'single-spa'
import { importSpa } from '../utils'
import { RegistrationFunc } from './registration'

const C3P_ENABLE_PRICING_UPDATES = 'c3p-enable-pricing-updates'

export const headerNotifications: RegistrationFunc = (globalCustomProps) => {
  // --------------------------------------
  // Define layout spa
  // --------------------------------------

  const isPricingUpdatesEnabled = Boolean(
    globalCustomProps.featureFlags?.[C3P_ENABLE_PRICING_UPDATES]
  )

  if (isPricingUpdatesEnabled) {
    registerApplication({
      name: 'restaurant-admin-header-notifications-spa',
      activeWhen: ['/restaurants/admin/home'],
      customProps: globalCustomProps,
      app: importSpa('restaurant-admin-header-notifications-spa')
    })
  }
}
