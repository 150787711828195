import * as LDClient from 'launchdarkly-js-client-sdk'
import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'

export const enhanceClient = (client: LDClient.LDClient) => {
  // Return original client if prod environment
  const isProd = getCurrentEnvironment() === ToastEnvironment.PROD
  if (isProd) return

  // Merge local storage and url params; give priority to url params
  const urlParamOverrides = getOverridesFromUrl()
  const localStorageOverrides = getOverridesFromLocalStorage()
  const overrides = { ...localStorageOverrides, ...urlParamOverrides }

  // Return original client if no override param exists
  if (objectIsEmpty(overrides)) return

  // Store original client.variation so we can call it for non-overridden flags
  const originalClientVariation = client.variation.bind(client)

  // If override param exists for a given key, return the override value
  // If no override param exists for a given key, return the original value
  client.variation = (key: string, defaultValue?: LDClient.LDFlagValue) => {
    if (key in overrides) {
      return overrides[key]
    } else {
      return originalClientVariation(key, defaultValue)
    }
  }
}

// Example query param: ?ldOverride={"feature-flag":false,"nested-feature-flag":{"nested":true}}
export const getOverridesFromUrl = (): Record<string, any> | null => {
  const queryParams = global.location.search
  const overrideParam = new URLSearchParams(queryParams).get('ldOverride')
  return overrideParam ? safeParse(overrideParam) : null
}

// Example key/value: ldOverride: {"feature-flag":false,"nested-feature-flag":{"nested":true}
export const getOverridesFromLocalStorage = (): Record<string, any> | null => {
  const overridesKey = 'ldOverride'
  const overridesString = localStorage.getItem(overridesKey)
  return overridesString ? safeParse(overridesString) : null
}

export const safeParse = (str: string): Record<string, any> | null => {
  try {
    return JSON.parse(str)
  } catch (error) {
    console.error('Failed to parse json:', error)
    return null
  }
}

export const objectIsEmpty = (obj: Record<string, any>): boolean =>
  !Object.keys(obj).length
