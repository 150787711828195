import { onError } from '@apollo/client/link/error'
import type { Hub } from '@sentry/types'
import { ApolloError } from '@apollo/client'

export const errorLink = (sentry?: Hub) =>
  onError(({ graphQLErrors, networkError, operation }) => {
    if (!sentry) return

    sentry.setContext('custom_info', {
      url: window.location.href,
      variables: operation.variables
    })

    sentry.withScope((scope) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          const code = error?.extensions?.code as string
          if (INFO_LEVEL_GRAPHQL_ERROR_CODES.has(code)) {
            scope.setLevel('info')
            scope.setFingerprint(['{{ default }}', code])
            scope.setTransactionName(
              `ApolloError - ${operation.operationName} - ${code}`
            )
          } else if (code) {
            scope.setFingerprint([
              '{{ default }}',
              operation.operationName,
              code
            ])
            scope.setTransactionName(
              `ApolloError - ${operation.operationName} - ${code}`
            )
          } else {
            scope.setFingerprint(['{{ default }}', operation.operationName])
            scope.setTransactionName(`ApolloError - ${operation.operationName}`)
          }
        })
      }

      if (networkError) {
        scope.setLevel('info')

        if (networkError.message === 'Failed to fetch') {
          scope.setFingerprint(['{{ default }}', 'FAILED_TO_FETCH'])
          scope.setTransactionName(
            `ApolloError - ${operation.operationName} - FAILED_TO_FETCH`
          )
          scope.setTag('url', operation.getContext().uri)
          scope.setTag('operationName', operation.operationName)
        }
      }

      sentry.captureException(new ApolloError({ graphQLErrors, networkError }))
    })
  })

const INFO_LEVEL_GRAPHQL_ERROR_CODES = new Set([
  'INTERNAL_SERVER_ERROR',
  '500_INTERNAL_SERVER_ERROR',
  'FORBIDDEN',
  '403_FORBIDDEN',
  '401_UNAUTHENTICATED',
  'UNAUTHENTICATED',
  '404_NOT_FOUND'
])
