import { FFLookup } from '@toasttab/banquet-types'
import { LDClient } from 'launchdarkly-js-client-sdk'

export const NAV_SURVEY_TOOL_MIGRATION = 'nav-survey-tool-migration'

export const useFeatureFlags = (
  featureFlags?: FFLookup,
  ldClient?: LDClient
) => {
  return {
    isMedalliaEnabled: ldClient?.variation(NAV_SURVEY_TOOL_MIGRATION, false)
  }
}
