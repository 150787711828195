import { ApolloLink } from '@apollo/client'
import { ErrorResponse } from '@apollo/client/link/persisted-queries'
import { print } from 'graphql'
import {
  createPersistedQueryLink,
  type PersistedQueryLink
} from '@apollo/client/link/persisted-queries'

export const createPersistedQueriesLink = (
  opts: Omit<
    PersistedQueryLink.Options & {
      disableErrorLogging?: boolean
    },
    'generateHash' | 'sha256'
  > = {
    disableErrorLogging: false
  }
) => {
  const debugPersistedOperations =
    localStorage.getItem('debugPersistedOperations') === 'true'

  let devHeaders = {}
  try {
    if (process.env.NODE_ENV === 'development') {
      devHeaders = {
        'Toast-Dev-Allow-Arbitrary-Operations': true
      }
    }
  } catch (e) {
    // Ignore error
  }

  const links = [
    new ApolloLink((operation, forward) => {
      if (!(operation.query as any)?.['__meta__']?.['hash']) {
        if (!opts.disableErrorLogging) {
          console.error('Operation does not have a hash:', operation.query)
        }
        return forward(operation)
      }

      if (debugPersistedOperations) {
        console.log(
          'Persisted hash:',
          (operation.query as any)['__meta__']?.['hash']
        )
        console.log('Persisted operation:', print(operation.query))
        console.log('Persisted operation variables:', operation.variables)
      }

      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          'Toast-Persistent-Query-Hash':
            (operation.query as any)['__meta__']?.['hash'] ?? 'unknown',
          ...devHeaders
        }
      }))
      return forward(operation)
    })
  ]

  if (!shouldSkipPersistedQueries()) {
    links.push(
      createPersistedQueryLink({
        generateHash: (query) => {
          return (query as any)?.['__meta__']?.['hash'] ?? 'unknown'
        },
        useGETForHashedQueries: true,
        // Default behaviour: Disable the link on failed requests (i.e. if pqr is not supported yet)
        disable: isPersistedQueryError,
        retry: isPersistedQueryError,
        ...opts
      })
    )
  }
  return ApolloLink.from(links)
}

const shouldSkipPersistedQueries = () => process.env.NODE_ENV === 'development'

const isPersistedQueryError = (err: ErrorResponse) =>
  err.graphQLErrors?.some(
    ({ extensions, message }) =>
      extensions?.code === 'PERSISTED_QUERY_NOT_FOUND' ||
      extensions?.code === 'PERSISTED_QUERY_ERROR' ||
      message === 'PersistedQueryNotFound' ||
      message === 'PersistedQueryHeaderNotFound' ||
      message === 'PersistedQueryRetrievalError'
  ) ?? false
