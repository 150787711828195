import * as LDClient from 'launchdarkly-js-client-sdk'
import { initAuthClient } from '@toasttab/user-authentication-js'
import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'
import { enhanceClient } from './enhanceClient'

export type AuthClientProps = Awaited<ReturnType<typeof initAuthClient>>

export type Options = {
  user: AuthClientProps['auth']['userInfo']
  restaurantInfo: AuthClientProps['restaurantInfo']
  featureOptIns: {
    key?: string | null
    value?: {
      value: boolean | null
      deferToDate?: string | null
      [key: string]: any
    } | null
    [key: string]: any
  }[]
  permissions: Record<string, boolean>
}

export const CLIENT_IDS: Record<ToastEnvironment, string> = {
  prod: '66280d4db45e530f92ccebb3',
  preprod: '66280d6fb21c100fdcd00059',
  sandbox: '66280d656f99a91062b4ce2d',
  test: '66280d4db45e530f92ccebb2',
  dev: '6630f32e13f9510fe191da86'
}

const environment = getCurrentEnvironment()

/**
 * This function is used to create and initialize a LaunchDarkly (LD) client.
 */
export async function createClient(context: LDClient.LDContext) {
  const options: LDClient.LDOptions = {}

  const client = LDClient.initialize(CLIENT_IDS[environment], context, options)
  await client.waitUntilReady()
  enhanceClient(client)

  return client
}

/**
 * This function is used to create a LaunchDarkly (LD) context.
 */
export function createContext(options: Options): LDClient.LDMultiKindContext {
  const featureOptIns: Record<string, boolean> =
    options.featureOptIns?.reduce((acc: Record<string, boolean>, feature) => {
      if (!feature.key || !feature.value) return acc

      acc[feature.key] = feature.value.value ?? false
      return acc
    }, {}) ?? {}

  return {
    kind: 'multi',
    user: {
      kind: 'user',
      key: options.user?.guid,
      roles: options.user?.roles,
      isToastAdministrator: options.user?.isToastAdministrator,
      featureOptIns: featureOptIns,
      permissions: options.permissions ?? {}
    },
    restaurant: {
      kind: 'restaurant',
      key: options.restaurantInfo?.restaurant?.guid,
      country: options.restaurantInfo?.restaurant?.country,
      currency: options.restaurantInfo?.restaurant?.currency,
      managementSetGuid: options.restaurantInfo?.managementSet?.guid,
      managementSetId: options.restaurantInfo?.managementSet?.id
    }
  }
}
