import 'focus-visible'

import { resetModulesWithFailedOverride } from '@toasttab/system-import'
import {
  captureException,
  isOnProductionEnvironments,
  importSpa
} from '../utils'
import { initialize } from './initialize'

//-------------------------------------------------------
// Preload the bundle
//-------------------------------------------------------
importSpa('banquet-runtime-modules')
importSpa('navigation-layout-spa')
importSpa('restaurant-navigation-spa')
importSpa('search-spa')

//-------------------------------------------------------
// Preload the bundle
//-------------------------------------------------------

const isOnDev = !isOnProductionEnvironments()

if (isOnDev) {
  resetModulesWithFailedOverride()
}

initialize().catch((e) => {
  captureException(e)
  if (isOnDev) {
    console.error(e)
  }
})
