import { ToastEnvironment } from '@toasttab/authentication-utils'
import { getEnvironment } from '@toasttab/current-environment'
import { addListener, SingleSPAEventName } from '@local/single-spa-events'
import { trackPageView } from '@local/tealium'
import { GlobalCustomProps } from '@local/global-custom-props'
import { updatePageView } from '@local/medallia-survey-spa'

/**
 * initializePageViewTracking
 *
 * listens to single SPA routing events and tracks pageViews to Tealium
 */
export function initializePageViewTracking(
  globalCustomProps: GlobalCustomProps
) {
  let prevPath = ''
  const unsub = addListener(SingleSPAEventName.ROUTING_EVENT, () => {
    const path = pathWithoutHash(global.location)
    const environment = getEnvironment(global.location.href)
    if (path !== prevPath) {
      prevPath = path
      trackView(global.location, environment, globalCustomProps)
    }
  })
  return unsub
}

function trackView(
  location: Location,
  environment: ToastEnvironment,
  globalCustomProps: GlobalCustomProps
) {
  const url = location.href
  const user = globalCustomProps.auth?.userInfo
  const { restaurantInfo } = globalCustomProps

  // Medallia track page view
  updatePageView()

  // Tealium track page view
  trackPageView({
    url,
    normalized_path: normalizePath(`${location.pathname}`),
    environment,
    user_guid: user?.guid!,
    first_name: user?.firstName!,
    last_name: user?.lastName!,
    phone_number: user?.phoneNumber!,
    email: user?.email!,
    location_guid: restaurantInfo?.restaurantGuid!
  })
}

function pathWithoutHash(location: Location): string {
  return `${location.pathname}${location.search}`
}

function normalizePath(path: string): string {
  return path.replace(
    new RegExp('[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?'),
    '{guid}'
  )
}
