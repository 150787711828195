import { clearCookies, getCookieSize, getSizeByCookie } from './cookieUtils'
import { ActionType, addAction } from './datadogRum'

const MAX_COOKIE_SIZE = 6000

const ONLINE_ORDERING_HEAP_PROJECT_ID = /^_hp2_.*1926653669/
const GUEST_CX_HEAP_PROJECT_ID = /^_hp2_.*3157978395/
const POS_HEAP_PROJECT_ID = /^_hp2_.*1227582568/
const CENTAL_HEAP_PROJECT_ID = /^(_hp5_|_hp2_).*3510146721/
const TABLES_HEAP_PROJECT_ID = /^_hp2_.*158027326/
const ONLINE_ORDERING_V4_AND_SITES_ID = /^_hp2_.*4210567126/
const PAY_AT_TABLE_WEB_ID = /^_hp2_.*1285398792/
const PAY_AT_TABLE_HEAP_PROJECT_DEV = /^_hp2_.*1217991794/

const HEAP_SESSION_REPLAY_REGEX = /userty\.core/
const BRAZE_COOKIE_REGEX = /^ab\.storage/
const AMPLITUDE_COOKIE_REGEX = /^AMP_/
const MIXPANEL_COOKIE_REGEX = /^mp_/
const ZERO_HEIGHT_COOKIE_REGEX = /^viewer_uid|^_zh_session|^amplitude_id/

// regex to match all cookies that are not essential to the app
const COOKIE_REGEX_PHASE_1 = new RegExp(
  [
    HEAP_SESSION_REPLAY_REGEX,
    BRAZE_COOKIE_REGEX,
    AMPLITUDE_COOKIE_REGEX,
    MIXPANEL_COOKIE_REGEX,
    ONLINE_ORDERING_HEAP_PROJECT_ID,
    GUEST_CX_HEAP_PROJECT_ID,
    ONLINE_ORDERING_V4_AND_SITES_ID,
    PAY_AT_TABLE_WEB_ID,
    PAY_AT_TABLE_HEAP_PROJECT_DEV,
    CENTAL_HEAP_PROJECT_ID
  ]
    .map((r) => r.source)
    .join('|')
)

const COOKIE_REGEX_PHASE_2 = new RegExp(
  [ZERO_HEIGHT_COOKIE_REGEX, POS_HEAP_PROJECT_ID, TABLES_HEAP_PROJECT_ID]
    .map((r) => r.source)
    .join('|')
)

/**
 * runCookieSafetyValve
 */
export function runCookieSafetyValve(isInternalUser: boolean) {
  const cookieSize = getCookieSize()
  const allCookieSizesBeforeSafetyValve = getSizeByCookie()
  if (cookieSize > MAX_COOKIE_SIZE) {
    console.log('removing non-essential cookies')
    // clear the first set of cookies
    clearCookies(COOKIE_REGEX_PHASE_1)
    console.log('phase 1 cookie removal complete')
    if (getCookieSize() > MAX_COOKIE_SIZE) {
      // if still a problem, clear the second set of cookies
      clearCookies(COOKIE_REGEX_PHASE_2)
      console.log('phase 2 cookie removal complete')
    }
  }
  const clearedCookieSize = getCookieSize()
  addAction(ActionType.RUN_COOKIE_VALVE, {
    isInternalUser,
    totalCookieSize: cookieSize,
    currentCookieSize: clearedCookieSize,
    cookies: {
      ...allCookieSizesBeforeSafetyValve
    }
  })
}
