import { type datadogRum } from '@datadog/browser-rum'

/* eslint-disable no-undef */

type DD_RUM = typeof datadogRum

export enum ActionType {
  RUN_COOKIE_VALVE = 'RUN_COOKIE_VALVE'
}

export function addAction(action: ActionType, metadata?: Record<string, any>) {
  getRUM()?.addAction?.(action, metadata)
}

function getRUM() {
  return (window as any)?.DD_RUM as DD_RUM | undefined
}
