import { registerApplication } from 'single-spa'
import { RegistrationFunc } from './registration'
import * as medaliaSurveySpa from '@local/medallia-survey-spa'

export const medallia: RegistrationFunc = (globalCustomProps) => {
  registerApplication({
    name: 'medallia-survey-spa',
    activeWhen: '/restaurants/admin',
    customProps: globalCustomProps,
    app: medaliaSurveySpa
  })
}
